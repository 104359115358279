import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { images } from '../constants';
import '../styles/About.scss';
import { urlFor, client } from '../client';
import { AppWrap } from '../wrapper';





import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import '../styles/Header.scss';
const scaleVarients = {
    whileInView: {
        scale: [0, 1],
        opacity: [0, 1],
        transition: {
            duration: 1,
            ease: 'easeInOut'
        }
    }
}
/*const abouts = [
    { title: 'Fullstack Developer', description: "Last year, I developed many full stack applications in Angular with Microsoft's ASP.NET framwork and Azure DevOps. Now I am having a blast learning React!", imageUrl: images.angular },
    { title: 'Mobile Developer', description: "Last year, I worked in a group of five to create a companion app to the card game Magic The Gathering. The app was written in Dart using Google's Flutter framework and Cloud Firestore", imageUrl: images.flutter },
    { title: 'Computer Scientist', description: "Last year, I worked with a colleague to create a search engine using Keywork In Context Indexing. It was written in Java while leveraging Jakarta Server Pages. It is hosted on an AWS EC2 instance", imageUrl: images.java },
    { title: 'Software Architect', description: "Now I'm currently working on an online Pascal compiler that is being built on the LACC (Linux, Apache, C++, CGI) stack.", imageUrl: images.bison },
    { title: 'Software Engineer', description: "Previously, I created several IoT devices by interfacing through a Raspberry Pi with a web interface by harnessing Cloud Firestore and Python", imageUrl: images.pi },
];*/

const About = () => {
    const [abouts, setAbouts] = useState([]);
    useEffect(() => {
        const query = '*[_type == "abouts"]';

        client.fetch(query).then((data) => setAbouts(data));
    }, [])


    return (
        <div id="about" className='app__about'>
            {/*<h2 className="head-text">My <span>Education</span></h2>*/}
            {/* <motion.div
                whileInView={{ y: [-100, 0], opacity: [0, 1] }}
                transition={{ duration: 0.5 }}
                className="app__header-info">
                <div className="app__header-badge">
                    <div className="badge-cmp app__flex">
                       
                        <div style={{ marginLeft: 20 }}>
                            <h1 className="head-text">Chad Marshall</h1>
                        </div>
                    </div>

                    <div className="tag-cmp app__flex">

                        <div className='typewriter'>
                            <p>&lt;</p><p className=" typer">Software Developer</p><p>&nbsp;/&gt;</p>
                        </div>



                    </div>
                </div>
            </motion.div>*/}
            <div className='row app__flex'>
                <div className='column'>


                    <div className="paper">
                        <h2 className="head-text">B.S. in <span>Computer Science</span></h2>
                        <p className='p-text app__flex'>
                            &nbsp;&nbsp;&nbsp;&nbsp;I earned a Bachelors of Science in Computer Science in December of 2022. During my degree program I learned various programming languages
                            like C++ and Python. I developed both lexical and syntactical analyzers for a Pascal compiler. Then I ported the latter into a web app. During my studies of
                            Relational Databases, I created schemas in SQL for an online student enrollment system. After that, I dived into the sea of system architecture and traveled from
                            multi-threaded systems to cache mapping. I also flirted with Assembly language during this time and learned that I never want to implement Binary Search in Assembly ever again.
                            Speaking of searches, I grew to enjoy creating data structures and sorting the data within.
                        </p>
                        <p className='p-text app__flex'>
                            This degree is accredited by the Computing Accreditation Commission of <strong><a className='abet' href="https://www.abet.org/" target="_blank">&nbsp;ABET&nbsp;</a></strong>.
                        </p>

                        <h2 className="head-text">B.S. in <span>Software Engineering</span></h2>
                        <p className='p-text app__flex'>
                            &nbsp;&nbsp;&nbsp;&nbsp;In the same year, I earned a Bachelors of Science in Software Engineering. My level of abstracting and subsequently solving software design problems
                            increased significantly when I learned various design patterns. I also expanded my knowledge of the Java programming language with object oriented design in mind. During this time, teamwork was at an all time high. I have taken part in virtually every role in the book from Tech Lead,
                            to Test Engineer, to Git Admin, to Pair Programmer. I create and record presentations for almost every project I do and upload them to YouTube. You can find them <strong><a className="abet" href="#presentations">here</a></strong>. Responsibilities I enjoy include writing
                            meaningful comments, filing sprint documentation and creating diagrams with StarUML.
                        </p>
                        <p className='p-text app__flex'>
                            This degree is accredited by the Engineering Accreditation Commission of <strong><a className='abet' href="https://www.abet.org/" target="_blank">&nbsp;ABET&nbsp;</a></strong>.
                        </p>
                        <h2 className="head-text">Minor in <span>Mathematics</span></h2>
                        <p className='p-text app__flex'>
                            &nbsp;&nbsp;&nbsp;&nbsp;Even before my first C# program, my mother tongue has always been mathematics. I absolutly crushed Calculus 1 through 4 and had a blast learning Linear Algebra. I have the ability to read and write mathematical proofs. This came in handy when I needed to analyze theoretical
                            computing problems like the Haulting problem. I also partake in mathematical research for my university and have written many essays in various fields. You can find them <strong><a className="abet" href="#publications">here</a></strong>.
                        </p>
                        <div className='row app__flex school'>
                            <a href="https://www.uco.edu/cms/academics/computer-science/" target="_blank">
                                <h2 className="head-text ">@&nbsp;</h2>
                            </a>
                            <a href="https://www.uco.edu/cms/academics/computer-science/" target="_blank">
                                <img className="uco" src={images.uco} />
                            </a>



                        </div>
                    </div>


                </div>


                <motion.div
                    whileInView={{ x: [100, 0], opacity: [0, 1] }}
                    transition={{ duration: 0.5, delayChildren: 0.5 }}
                    className="app__header-img app__flex column">
                    <img src={images.kendo} alt="kendo" />
                </motion.div>
            </div>



            {/* <motion.img
                    whileInView={{ scale: [0, 1] }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    className="overlay_circle"
                    src={images.circle} alt="profile_circle" />
                    */}

            {// Move this to individual project pages
            /*
            <div>
                <motion.div
                    whileInView={{ x: [100, 0], opacity: [0, 1] }}
                    transition={{ duration: 0.5 }}
                    className="app__header-info-alt">
                    <div className="app__header-badge">
                        <div className="badge-cmp app__flex" style={{ marginBottom: 20 }}>

                            <div >
                                <h1 className="head-text">Favorite Tech</h1>
                            </div>
                        </div>
                    </div>
                </motion.div>
                <motion.div
                    variants={scaleVarients}
                    whileInView={scaleVarients.whileInView}
                    className="app__header-circles">

                    {[images.dart, images.cpp, images.java, images.react, images.python].map((circle, index) => (
                        <div className="circle-cmp app__flex" key={`circle-${index}`}>
                            <img src={circle} alt="circle" />
                        </div>
                    ))}

                </motion.div>
            </div>*/}

        </div>
    )
}

export default AppWrap(About, 'about')