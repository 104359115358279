import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { images } from '../constants';
import '../styles/Hats.scss';
import { urlFor, client } from '../client';
import { AppWrap } from '../wrapper';
/*const abouts = [
    { title: 'Fullstack Developer', description: "Last year, I developed many full stack applications in Angular with Microsoft's ASP.NET framwork and Azure DevOps. Now I am having a blast learning React!", imageUrl: images.angular },
    { title: 'Mobile Developer', description: "Last year, I worked in a group of five to create a companion app to the card game Magic The Gathering. The app was written in Dart using Google's Flutter framework and Cloud Firestore", imageUrl: images.flutter },
    { title: 'Computer Scientist', description: "Last year, I worked with a colleague to create a search engine using Keywork In Context Indexing. It was written in Java while leveraging Jakarta Server Pages. It is hosted on an AWS EC2 instance", imageUrl: images.java },
    { title: 'Software Architect', description: "Now I'm currently working on an online Pascal compiler that is being built on the LACC (Linux, Apache, C++, CGI) stack.", imageUrl: images.bison },
    { title: 'Software Engineer', description: "Previously, I created several IoT devices by interfacing through a Raspberry Pi with a web interface by harnessing Cloud Firestore and Python", imageUrl: images.pi },
];*/

const Hats = () => {
    const [abouts, setAbouts] = useState([]);
    useEffect(() => {
        const query = '*[_type == "abouts"]';

        client.fetch(query).then((data) => setAbouts(data));
    }, [])


    return (
        <div id="hats">
            <h2 className="head-text">A Few <span>Hats</span> I Wear</h2>
            <div className="app__profiles">
                {abouts.map((about, index) => (
                    <motion.div
                        whileInView={{ opacity: 1 }}
                        whileHover={{ scale: 1.1 }}
                        transition={{ duration: 0.5, type: 'tween' }}
                        className="app__profile-item"
                        key={about.title + index}>
                        {/*<img src={about.imageUrl} alt={about.title} />*/}
                        <img src={urlFor(about.imgUrl)} alt={about.title} />
                        <h2 className="bold-text" style={{ marginTop: 20 }}>{about.title}</h2>
                        <p className="p-text" style={{ marginTop: 10 }}>{about.description}</p>
                    </motion.div>
                ))}
            </div>
        </div>
    )
}

export default AppWrap(Hats, 'hats')