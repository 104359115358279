import React from 'react'
import { AiFillGithub, AiFillYoutube, AiFillLinkedin } from 'react-icons/ai';


const SocialMedia = () => {
    return (
        <div className='app__social'>

            <a href='https://github.com/LordFreezer' target='_blank'>
                <div>
                    <AiFillGithub />
                </div>
            </a>


            <a href='https://www.youtube.com/channel/UC8qMMhzwUivihcYNA8FGjkQ' target='_blank'>
                <div>
                    <AiFillYoutube />
                </div>
            </a>


            <a href='https://www.linkedin.com/in/chad-marshall-dev/' target='_blank'>
                <div>
                    <AiFillLinkedin />
                </div>
            </a>


        </div>
    )
}

export default SocialMedia