import React from 'react'
import { NavigationDots, SocialMedia } from '../components';
const AppWrap = (Component, idName, classNames) => function HOC() {
    return (
        <div id={idName} className={`app__container ${classNames}`}>

            <div className='app__wrapper app__flex'>
                <Component />
                {/* <div className='copyright'>
                    <p className='p-text'>@2022 Derflinger</p>
                    <p className='p-text'>All Rights Reserved</p>
                </div>*/}

            </div>
            <SocialMedia />
            {/*<NavigationDots active={idName} />*/}
        </div>
    )
}

export default AppWrap