import { useEffect } from 'react';
export const useDisableBodyScroll = (open, ref) => {
    useEffect(() => {
        if (open) {
            document.documentElement.style.overflowY = 'hidden';
            document.body.scroll = "no";

        } else {
            document.documentElement.style.overflowY = 'scroll';
            document.body.scroll = "unset";
        }
    }, [open]);
};