import React, { useState, useEffect } from 'react';
import { AiFillGithub } from 'react-icons/ai';
import { IoOpenOutline } from 'react-icons/io5';
import { motion } from 'framer-motion';
import { AppWrap } from '../wrapper';
import { urlFor, client } from '../client';
import '../styles/Skills.scss';
const Skills = () => {
    const [activeFilter, setActiveFilter] = useState('All');
    const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });
    const [pres, setPres] = useState([]);


    useEffect(() => {
        const query = '*[_type == "presentations"]';

        client.fetch(query).then((data) => {

            setPres(data);

        });
    }, [])

    const handleWorkFilter = (item) => {


        setTimeout(() => {
            setAnimateCard([{ y: 0, opacity: 1 }]);
        }, 500);
    }
    return (
        <div id="presentations">
            <h2 className='head-text'>Presentation <span>Videos</span></h2>



            <motion.div
                animate={animateCard}
                transition={{ duration: 0.5, delayChildren: 0.5 }}

            >
                {pres.map((pre, index) => (
                    <div key={index}>
                        <div >
                            <motion.div
                                whileInView={{ x: [100, 0], opacity: [0, 1] }}
                                transition={{ duration: 0.5 }}
                                className="app__header-info">
                                <div className="app__header-badge-skills">
                                    <div className="heading app__flex" style={{ marginBottom: 20 }}>
                                        <div >
                                            <h1 className='head-text'>{pre.title}</h1>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>


                            <div className='encap'>

                                {
                                    pre.videos.map((video) => (
                                        <div className='app__flex video'>
                                            <iframe loading="lazy" src={video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullFcreen></iframe>
                                        </div>
                                    ))}

                            </div>



                        </div>
                    </div>
                ))
                }
            </motion.div >
        </div >
    )
}

export default AppWrap(Skills, 'presentations');