import React from 'react';
import { motion } from 'framer-motion';
import { images } from '../constants';
import { AppWrap } from '../wrapper';
import { Carousel } from 'react-responsive-carousel';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import '../styles/Header.scss';
const scaleVarients = {
    whileInView: {
        scale: [0, 1],
        opacity: [0, 1],
        transition: {
            duration: 1,
            ease: 'easeInOut'
        }
    }
}
const Header = () => {
    return (
        <div id="home" className="app__header app__flex">
            <motion.div
                whileInView={{ y: [-100, 0], opacity: [0, 1] }}
                transition={{ duration: 0.5 }}
                className="app__header-info">
                <motion.img
                    whileInView={{ scale: [0, 1] }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    className="circle"
                    src={images.square} alt="profile_circle" />
                {/* <div className="app__header-badge">
                    <div className="badge-cmp app__flex">
                        <span>&lt;&nbsp;<span className='blink'>/</span>&gt;</span>
                        <div style={{ marginLeft: 20 }}>
                            <h1 className="head-text">Chad Marshall</h1>
                        </div>
                    </div>

                    <div className="tag-cmp app__flex">
                        <p className="p-text">Software Developer</p>
                    </div>
                </div>*/}
                <div className="app__header-badge">
                    <div className="badge-cmp app__flex">
                        {/*<span>&lt;&nbsp;<span className='blink'>/</span>&gt;</span>*/}
                        <div >
                            <h1 className="head-text">Chad Marshall</h1>
                        </div>
                    </div>

                    <div className="tag-cmp app__flex ">

                        <div className='typewriter'>
                            <p>&lt;</p><p className=" typer">Software Developer</p><p>&nbsp;/&gt;</p>
                        </div>





                    </div>
                </div>
            </motion.div>




            {/*<motion.div
                whileInView={{ x: [100, 0], opacity: [0, 1] }}
                transition={{ duration: 0.5, delayChildren: 0.5 }}
                className="app__header-img-alt app__flex">
                <Carousel showThumbs={false} interval={3000} infiniteLoop={true}>
                    <div className='slides'>
                        <img src={images.profile2} alt="profile_bg" />
                    </div>
                    <div className='slides'>
                        <img src={images.kendo} alt="kendo" />
                    </div>
                </Carousel>


                

            </motion.div>*/}

            {/* <motion.img
                    whileInView={{ scale: [0, 1] }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    className="overlay_circle"
                    src={images.circle} alt="profile_circle" />
                    */}


            {// Move this to individual project pages
            /*
            <div>
                <motion.div
                    whileInView={{ x: [100, 0], opacity: [0, 1] }}
                    transition={{ duration: 0.5 }}
                    className="app__header-info-alt">
                    <div className="app__header-badge">
                        <div className="badge-cmp app__flex" style={{ marginBottom: 20 }}>

                            <div >
                                <h1 className="head-text">Favorite Tech</h1>
                            </div>
                        </div>
                    </div>
                </motion.div>
                <motion.div
                    variants={scaleVarients}
                    whileInView={scaleVarients.whileInView}
                    className="app__header-circles">

                    {[images.dart, images.cpp, images.java, images.react, images.python].map((circle, index) => (
                        <div className="circle-cmp app__flex" key={`circle-${index}`}>
                            <img src={circle} alt="circle" />
                        </div>
                    ))}

                </motion.div>
            </div>*/}


        </div>
    )
}

export default AppWrap(Header, 'home');