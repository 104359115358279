import React from 'react';
import Wave from 'react-wavify';
import emailjs from 'emailjs-com';
import { AppWrap } from '../wrapper';
import { images } from '../constants';
import { Footer } from './'
import '../styles/Contact.scss';
import { useState } from 'react';
const Contact = () => {
    const [game, setGame] = useState(false)

    function sendEmail(e) {
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
        // serviceID, templateID, e.target, userID

        emailjs.sendForm('service_2rt6ru5', 'template_wy9r2pc', e.target, 'spmBBSnUDnwTX_4jW')
            .then((result) => {
                document.getElementById("form").reset();
                setGame(true);
                setTimeout(function () {
                    window.location.hash = '#foot'
                }, 1000);

                //window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
            }, (error) => {
                console.log(error.text);
            });
    }
    return (
        <div id="contact" className='contact'>

            <h2 className='head-text'>Drop Me An <span>Anchor</span></h2>

            <Wave fill='#2674A9'
                paused={false}
                options={{
                    height: 80,
                    amplitude: 20,
                    speed: 0.15,
                    points: 3

                }}
            />

            <div className='ocean app__flex'>
                <form id="form" autoComplete="off" noValidate onSubmit={sendEmail}>
                    <input type="hidden" name="contact_number" />
                    <input name="from_name" varient="outlined"
                        placeholder="Name" fullWidth />
                    <input name="from_email" varient="outlined"
                        placeholder="Email" fullWidth />
                    <input name="subject" varient="outlined"
                        placeholder="Subject" fullWidth />
                    <textarea name="message" varient="outlined"
                        placeholder="Message" fullWidth />
                    <button
                        variant="contained" color="primary"
                        size="large" type="submit" fullWidth>
                        Send
                    </button>
                </form>
                <div className='anchor app__flex'>
                    <img className='app__flex' src={images.anchor}></img>
                </div>
            </div>
            {
                game
                    ? <>
                        <Footer />
                    </>
                    : null
            }
        </div>
    )
}

//export default AppWrap(Contact, 'contact')
export default Contact;