import React, { useState, useEffect } from 'react';
import { AiFillGithub } from 'react-icons/ai';
import { IoOpenOutline } from 'react-icons/io5';
import { motion } from 'framer-motion';
import { AppWrap } from '../wrapper';
import { urlFor, client } from '../client';
import { images } from '../constants';
import ImageGrid from "react-image-grid-animator";
import '../styles/Crowned.scss';
import Tilt from 'react-parallax-tilt';

const Crowned = () => {
    const [activeFilter, setActiveFilter] = useState('All');
    const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });
    const [works, setWorks] = useState([]);
    const [filterWork, setFilterWork] = useState([]);
    const projects = [
        {
            cardVideo: 'https://www.youtube.com/embed/To3dUT5bUEg',
            cardTitle: 'MTG Companion App',
            cardSubTitle: 'Chad Marshall, Sonny Icks, Julian Shen, Giovanni Soto, and Chris Syfrett',
            cardDetails: 'This is a companion app to the game Magic: The Gathering. You can search for individual cards or browse entire sets. Once you find the card you are looking for, you can either add it to a deck, wishlist, or cart. After you finish building your deck, you can go into the Forum and post your thoughts. You can also message specific users you meet in your journey.',
            cardTech: [
                {
                    tech: images.flutter,
                    link: 'flutter'
                }, {
                    tech: images.dart,
                    link: 'dart'
                }, {
                    tech: images.firebase,
                    link: 'firebase'
                }, {
                    tech: images.scryfall,
                    link: 'scryfall'
                }, {
                    tech: images.mtgio,
                    link: 'mtgio'
                }
            ],
            projectLink: 'https://www.youtube.com/watch?v=7kCMnWqSWG4&t=12s',
            codeLink: '',

        },
        {
            cardVideo: 'https://www.youtube.com/embed/JqWpeSQA1kg',
            cardTitle: 'Keyword In Context Search Engine',
            cardSubTitle: 'Chad Marshall and Stephen Key',
            cardDetails: 'We developed this search engine using KWIC+ indexing where a string of keywords is circular shifted to the left for every word in the string. The list of strings are then alphabetized. This is done as the action of the form when the user submits a search query. Only the original url-description pairs are stored in the database.',
            cardTech: [
                {
                    tech: images.java,
                    link: ''
                }, {
                    tech: images.ec2,
                    link: ''
                }, {
                    tech: images.tomcat,
                    link: ''
                }, {
                    tech: images.jsp,
                    link: ''
                }, {
                    tech: images.mysql,
                    link: ''
                }
            ],
            projectLink: 'https://www.youtube.com/watch?v=mr4-J27xBmY',
            codeLink: 'https://github.com/LordFreezer/KWIC-Web-App-With-MySQL-On-Tomcat',

        },
    ]
    /*useEffect(() => {
        const query = '*[_type == "works"]';

        client.fetch(query).then((data) => {
            setWorks(data);
            setFilterWork(data);
        });
    }, [])

    const handleWorkFilter = (item) => {
        setActiveFilter(item);

        setTimeout(() => {
            setAnimateCard([{ y: 0, opacity: 1 }]);
            if (item === 'All') {
                setFilterWork(works);
            } else {
                //setFilterWork(works.filter((work) => work.tags.includes(item)))
                setFilterWork(works.filter((work) => work.tags.find(tag => tag.includes(item))))

            }
        }, 500);
    }*/
    return (
        <div id="crowned">
            <h2 className='head-text crown app__flex'>Crowned&nbsp;<span>Projects</span><img src="https://svgsilh.com/svg/1837391.svg" /></h2>
            {
                projects.map((project, index) => (

                    (index % 2 == 0 || window.screen.width < 860)
                        ? (

                            <figure className='card_test'>
                                <div className='card_hero'>
                                    <iframe className='card_img' loading="lazy" src={project.cardVideo} title="YouTube video player" frameBorder="0" allow="autoplay; encrypted-media; picture-in-picture" allowFullFcreen></iframe>
                                </div>
                                <div className='card_content'>
                                    <div className='card_info'>
                                        <h5 className='card_title'>{project.cardTitle}</h5>
                                        <p className='card_sub-title'>{project.cardSubTitle}</p>
                                        <p className='card_details'> {project.cardDetails}</p>
                                    </div>

                                    <div className='card_tech'>
                                        {
                                            (parseInt(project.cardTech.length) < 4)
                                                ? (project.cardTech.map(pic => (
                                                    <>
                                                        <div className='contain_img'>
                                                            <img src={pic.tech} />
                                                        </div>
                                                    </>
                                                )))
                                                : (
                                                    <>
                                                        <ImageGrid
                                                            images={project.cardTech.map(pic => pic.tech)}
                                                            visibleCount={3}
                                                            interval={2000}
                                                            animationItemcount={2}
                                                            transitionType={"SCALE"}
                                                            transitionDuration={200}
                                                            isActive={true}

                                                        />
                                                    </>
                                                )
                                        }

                                    </div>
                                    <div className='card_btns'>
                                        <motion.div

                                            transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5 }}
                                            className='app__tech-hover app__flex'
                                        >
                                            <a href={project.projectLink} target="_blank" rel='norefer'>
                                                <motion.div
                                                    whileInView={{ scale: [0, 1] }}
                                                    whileHover={{ scale: [1, 0.9] }}
                                                    transition={{ duration: 0.25 }}
                                                    className='app__flex'
                                                >
                                                    <IoOpenOutline />

                                                </motion.div>
                                            </a>
                                            <a href={project.codeLink} target="_blank" rel='norefer'>
                                                <motion.div
                                                    whileInView={{ scale: [0, 1] }}
                                                    whileHover={{ scale: [1, 0.9] }}
                                                    transition={{ duration: 0.25 }}
                                                    className='app__flex'
                                                >
                                                    <AiFillGithub />
                                                </motion.div>
                                            </a>
                                        </motion.div>
                                    </div>

                                </div>

                            </figure>

                        )
                        : (


                            <figure className='card_test'>

                                <div className='card_content'>
                                    <div className='card_info'>
                                        <h5 className='card_title'>{project.cardTitle}</h5>
                                        <p className='card_sub-title'>{project.cardSubTitle}</p>
                                        <p className='card_details'> {project.cardDetails}</p>
                                    </div>

                                    <div className='card_tech'>
                                        {
                                            (parseInt(project.cardTech.length) < 4)
                                                ? (project.cardTech.map(pic => (
                                                    <div className='contain_img'>
                                                        <img src={pic.tech} />
                                                    </div>
                                                )))
                                                : (
                                                    <ImageGrid
                                                        images={project.cardTech.map(pic => pic.tech)}
                                                        visibleCount={3}
                                                        interval={2000}
                                                        animationItemcount={2}
                                                        transitionType={"SCALE"}
                                                        transitionDuration={200}
                                                        isActive={true}
                                                    />
                                                )
                                        }

                                    </div>
                                    <div className='card_btns'>
                                        <motion.div

                                            transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5 }}
                                            className='app__tech-hover-alt app__flex'
                                        >
                                            <a href={project.projectLink} target="_blank" rel='norefer'>
                                                <motion.div
                                                    whileInView={{ scale: [0, 1] }}
                                                    whileHover={{ scale: [1, 0.9] }}
                                                    transition={{ duration: 0.25 }}
                                                    className='app__flex'
                                                >
                                                    <IoOpenOutline />

                                                </motion.div>
                                            </a>
                                            <a href={project.codeLink} target="_blank" rel='norefer'>
                                                <motion.div
                                                    whileInView={{ scale: [0, 1] }}
                                                    whileHover={{ scale: [1, 0.9] }}
                                                    transition={{ duration: 0.25 }}
                                                    className='app__flex'
                                                >
                                                    <AiFillGithub />
                                                </motion.div>
                                            </a>
                                        </motion.div>
                                    </div>

                                </div>
                                <div className='card_hero'>
                                    <iframe className='card_img' loading="lazy" src={project.cardVideo} title="YouTube video player" frameBorder="0" allow="autoplay; encrypted-media; picture-in-picture" allowFullFcreen></iframe>

                                </div>
                            </figure>

                        )

                ))
            }


        </div>
    )
}

export default AppWrap(Crowned, 'crowned');