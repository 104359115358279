import React from 'react';
import '../styles/Footer.scss';

const Footer = () => {
    return (
        <div id="foot" className="app__flex">
            <h2 className='head-text'>Thanks For <span>Visiting</span>!</h2>
            <embed type="text/html" src="https://superchadman.netlify.app/" width="800" height="600" />
        </div>
    )
}

export default Footer;