import React, { useState, useEffect } from 'react';
import { AiFillGithub } from 'react-icons/ai';
import { IoOpenOutline } from 'react-icons/io5';
import { motion } from 'framer-motion';
import { AppWrap } from '../wrapper';
import { urlFor, client } from '../client';
import { images } from '../constants';
import '../styles/Publications.scss';
const Publications = () => {

    return (
        <div id="publications">
            <h2 className="head-text">Published <span>Work</span></h2>

            <div className="app__profiles_pub">
                <motion.div
                    whileInView={{ opacity: 1 }}
                    whileHover={{ scale: 1.1 }}
                    transition={{ duration: 0.5, type: 'tween' }}
                    className="app__profile-item_pub"
                >
                    <img src={images.fortran} alt="fortran" />
                    <h2 className="bold-text" style={{ marginTop: 20 }}>Fortran Programming Language Analysis</h2>
                    <p className="p-text" style={{ marginTop: 10 }}>Chad Marshall</p>
                    <p className="p-text" style={{ marginTop: 10 }}>Year: 2021</p>
                    <a href="https://fortranprogramminglanguageanalysis.netlify.app/" target="_blank"><div className='bott-btn app__flex'>View Paper 📜</div></a>
                </motion.div>
                <motion.div
                    whileInView={{ opacity: 1 }}
                    whileHover={{ scale: 1.1 }}
                    transition={{ duration: 0.5, type: 'tween' }}
                    className="app__profile-item_pub"
                >
                    <img src={images.newtype} alt="newtype" />
                    <h2 className="bold-text" style={{ marginTop: 20 }}>The Newtype Theory</h2>
                    <p className="p-text" style={{ marginTop: 10 }}>Chad Marshall</p>
                    <p className="p-text" style={{ marginTop: 10 }}>Year: 2019</p>
                    <a href="https://discoursecommunitytheory.netlify.app/" target="_blank"><div className='bott-btn app__flex'>View Paper 📜</div></a>
                </motion.div>
                <motion.div
                    whileInView={{ opacity: 1 }}
                    whileHover={{ scale: 1.1 }}
                    transition={{ duration: 0.5, type: 'tween' }}
                    className="app__profile-item_pub"
                >
                    <img src={images.beowulf} alt="beowulf" />
                    <h2 className="bold-text" style={{ marginTop: 20 }}>Inconsistencies in Beowulf's Monsters  </h2>
                    <p className="p-text" style={{ marginTop: 10 }}>Chad Marshall</p>
                    <p className="p-text" style={{ marginTop: 10 }}>Year: 2019</p>
                    <a href="https://beowulfpaper.netlify.app/" target="_blank"><div className='bott-btn app__flex'>View Paper 📜</div></a>
                </motion.div>


            </div>




        </div >
    )
}

export default AppWrap(Publications, 'publications');