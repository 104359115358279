import React, { useState, useEffect } from 'react';
import WorkChild from '../components/WorkChild';
import { AiFillGithub } from 'react-icons/ai';
import { IoOpenOutline } from 'react-icons/io5';
import { motion } from 'framer-motion';
import { AppWrap } from '../wrapper';
import { urlFor, client } from '../client';
import '../styles/Work.scss';




const Work = () => {
    const [activeFilter, setActiveFilter] = useState('👑');
    const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });
    const [works, setWorks] = useState([]);
    const [filterWork, setFilterWork] = useState([]);



    useEffect(() => {
        const query = '*[_type == "works"]';

        client.fetch(query).then((data) => {
            setWorks(data);
            setFilterWork(data.filter((work) => work.tags.find(tag => tag.includes('👑'))));

        });
    }, [])

    const handleWorkFilter = (item) => {
        setActiveFilter(item);

        setTimeout(() => {
            setAnimateCard([{ y: 0, opacity: 1 }]);
            if (item === 'All') {
                setFilterWork(works);
            } else {
                //setFilterWork(works.filter((work) => work.tags.includes(item)))
                setFilterWork(works.filter((work) => work.tags.find(tag => tag.includes(item))))

            }
        }, 500);
    }
    return (
        <div id="projects">
            <h2 className='head-text'>Project <span>Library</span></h2>

            <div className='app__work-filter'>
                {['👑', 'All', 'Angular', 'React', 'ThreeJS', 'RevealJS', 'HTML', 'Sass', 'Flutter', 'Java', 'C++', 'C#', 'Pascal', 'Fortran', 'Python', 'Raspberry Pi', 'Database', 'CMS', '🤝'].map((item, index) => (
                    <div
                        key={index}
                        onClick={() => handleWorkFilter(item)}
                        className={`app__work-filter-item app__flex p-text ${activeFilter === item ? 'item-active' : ''}`}
                    >
                        {item}

                    </div>
                ))}
            </div>
            {
                (activeFilter === '🤝') ? (
                    <motion.div
                        whileInView={{ x: [100, 0], opacity: [0, 1] }}
                        transition={{ duration: 0.5 }}
                        className="app__header-info-alt">
                        <div className="app__header-badge-work">
                            <div className="badge-cmp app__flex" style={{ marginBottom: 20 }}>
                                <div >
                                    <h1 className="head-text">🤝 Handheld Projects</h1>
                                    <p>I work through tutorial videos in my free time when Im learning new tech, so I mark them 🤝 for transparency</p>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                ) : (<></>)
            }
            <motion.div
                animate={animateCard}
                transition={{ duration: 0.5, delayChildren: 0.5 }}
                className='app__work-portfolio'
            >
                {filterWork.map((work, index) => (
                    <div className='app__work-item app__flex' key={index}>
                        <div className='app__work-img app__flex'>
                            <img src={urlFor(work.imgUrl)} alt={work.name} />
                            <motion.div
                                whileHover={{ opacity: [0, 1] }}
                                transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5 }}
                                className='app__work-hover app__flex'
                            >
                                <a href={work.projectLink} target="_blank" rel='norefer'>
                                    <motion.div
                                        whileInView={{ scale: [0, 1] }}
                                        whileHover={{ scale: [1, 0.9] }}
                                        transition={{ duration: 0.25 }}
                                        className='app__flex'
                                    >
                                        <IoOpenOutline />

                                    </motion.div>
                                </a>
                                <a href={work.codeLink} target="_blank" rel='norefer'>
                                    <motion.div
                                        whileInView={{ scale: [0, 1] }}
                                        whileHover={{ scale: [1, 0.9] }}
                                        transition={{ duration: 0.25 }}
                                        className='app__flex'
                                    >
                                        <AiFillGithub />
                                    </motion.div>
                                </a>
                            </motion.div>
                        </div>
                        <div className='app__work-content app__flex'>
                            <h4 className='bold-text'>{work.title}</h4>
                            <p className='p-text' style={{ marginTop: 10 }}>{work.description}</p>

                            <WorkChild work={work} />


                            <div className='app__work-tag app__flex'>
                                <marquee className='p-text'>
                                    {work.tags.filter((tag) => tag !== 'All').map((tag) => (
                                        <>{tag},&nbsp;&nbsp;</>
                                    ))}
                                </marquee>
                                {/*<p className='p-text'>{work.tags[0]}</p>*/}
                            </div>
                        </div>
                    </div>
                ))
                }
            </motion.div >
        </div >
    )
}

export default AppWrap(Work, 'work');