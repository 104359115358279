//import React from 'react'
import Wave from 'react-wavify';
import ReactDom from 'react-dom';
import { motion } from 'framer-motion';
import { urlFor } from '../client';
import '../styles/Modal.scss';
import React from 'react';
import { useDisableBodyScroll } from './scrollhook';

export default function Modal({ open, work, onClose }) {
    useDisableBodyScroll(open);
    if (!open) return null;

    return ReactDom.createPortal(
        <>
            <motion.div
                whileInView={{ y: [100, 0] }}
                transition={{ duration: 2 }}
                className="overlay-styles" >
                <div className="wav">
                    <Wave fill='rgba(26, 37, 65, 0.7)'
                        paused={false}
                        options={{
                            height: 80,
                            amplitude: 20,
                            speed: 1,
                            points: 3

                        }}></Wave>
                </div>
                <div className="bubble bubble--1"></div>
                <div className="bubble bubble--2"></div>
                <div className="bubble bubble--3"></div>
                <div className="bubble bubble--4"></div>
                <div className="bubble bubble--5"></div>
                <div className="bubble bubble--6"></div>
                <div className="bubble bubble--7"></div>
                <div className="bubble bubble--8"></div>
                <div className="bubble bubble--9"></div>
                <div className="bubble bubble--10"></div>
                <div className="bubble bubble--11"></div>
                <div className="bubble bubble--12"></div>
                <div id="octocat"></div>
            </motion.div>

            <div className="modal-styles">
                <div className='container-modal'>
                    <div><span onClick={onClose} className="close">☠</span></div>
                    <h1 className="app__flex">Modal Development Still In Progress 😝</h1>
                    <h1 className="app__flex">{work.title}</h1>
                    <div className='row-modal'>
                        <div class='column-modal'>
                            <h1>The Stack</h1>
                            {(work.front) ? (<h3>Front-End</h3>) : (<></>)}
                            <div className='row-modal-tech'>
                                {(work.front)
                                    ? work.front.map(item => (
                                        <div class='column-modal-tech'>
                                            <img src={urlFor(item.imgUrl)}></img>
                                            <p>{item.tech}</p>
                                        </div>
                                    ))
                                    : (<></>)
                                }
                            </div>
                            {(work.back) ? (<h3>Back-End</h3>) : (<></>)}
                            <div className='row-modal-tech'>
                                {(work.back)
                                    ? work.back.map(item => (
                                        <div class='column-modal-tech'>
                                            <img src={urlFor(item.imgUrl)}></img>
                                            <p>{item.tech}</p>
                                        </div>
                                    )) : (<></>)
                                }
                            </div>
                            {(work.libraries) ? (<h3>Libraries</h3>) : (<></>)}
                            <div className='row-modal-tech'>
                                {(work.libraries)
                                    ? work.libraries.map(item => (
                                        <div class='column-modal-tech'>
                                            <img src={urlFor(item.imgUrl)}></img>
                                            <p>{item.tech}</p>
                                        </div>
                                    )) : (<></>)
                                }
                            </div>
                            {(work.apis) ? (<h3>APIs</h3>) : (<></>)}
                            <div className='row-modal-tech'>
                                {(work.apis)
                                    ? work.apis.map(item => (
                                        <div class='column-modal-tech'>
                                            <img src={urlFor(item.imgUrl)}></img>
                                            <p>{item.tech}</p>
                                        </div>
                                    )) : (<></>)
                                }
                            </div>
                            {(work.deployment) ? (<h3>Deployment</h3>) : (<></>)}
                            <div className='row-modal-tech'>
                                {(work.deployment)
                                    ? work.deployment.map(item => (
                                        <div class='column-modal-tech'>
                                            <img src={urlFor(item.imgUrl)}></img>
                                            <p>{item.tech}</p>
                                        </div>
                                    )) : (<></>)
                                }
                            </div>
                        </div>
                        <div className='column-modal'>
                            <iframe width="421" height="240" src="https://www.youtube.com/embed/DPe2diiGJRE" title="Wellerman (Sea Shanty)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
                            <div className="desc">
                                <p>Who made it</p>
                                <p>Purpose</p>
                                <p>My Role</p>
                                <p>What I learned</p>
                            </div>
                            <div className="row-modal app__flex">
                                <motion.div
                                    whileInView={{ scale: [0, 1] }}
                                    whileHover={{ scale: [1, 1.1] }}
                                    transition={{ duration: 0.25 }}
                                    className='bott-btn'>Live View</motion.div>
                                <motion.div
                                    whileInView={{ scale: [0, 1] }}
                                    whileHover={{ scale: [1, 1.1] }}
                                    transition={{ duration: 0.25 }}
                                    className='bott-btn'>Git Code</motion.div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </>, document.getElementById("portal")
    )
}
