import directions from '../assets/directions.png';
import dart from '../assets/dart.png';
import java from '../assets/java.png';
import angular from '../assets/angular.png';
import email from '../assets/email.png';
import mobile from '../assets/mobile.png';
import api from '../assets/api.png';
import cpp from '../assets/cpp.png';
import css from '../assets/css.png';
import figma from '../assets/figma.png';
import flutter from '../assets/flutter.png';
import git from '../assets/git.png';
import graphql from '../assets/graphql.png';
import html from '../assets/html.png';
import javascript from '../assets/javascript.png';
import mu5 from '../assets/mu5.png';
import node from '../assets/node.png';
import python from '../assets/python.png';
import react from '../assets/react.png';
import redux from '../assets/redux.png';
import sass from '../assets/sass.png';
import typescript from '../assets/typescript.png';
import vue from '../assets/vue.png';

import about01 from '../assets/about01.png';
import about02 from '../assets/about02.png';
import about03 from '../assets/about03.png';
import about04 from '../assets/about04.png';

import profile from '../assets/profile.png';
import circle from '../assets/circle.svg';
import logo from '../assets/logo.png';

import adidas from '../assets/adidas.png';
import amazon from '../assets/amazon.png';
import asus from '../assets/asus.png';
import bolt from '../assets/bolt.png';
import nb from '../assets/nb.png';
import skype from '../assets/skype.png';
import spotify from '../assets/spotify.png';

import bison from '../assets/bison.png';
import pi from '../assets/pi.png';
import profile2 from '../assets/profile_2.png';
import anchor from '../assets/anchor.png';
import kendo from '../assets/kendo.png';
import homeScreen from '../assets/home_screen.png';
import firebase from '../assets/firebase.png';
import scryfall from '../assets/scryfall.png';
import mtgio from '../assets/mtgio.png';
import ec2 from '../assets/amazonec2.png';
import tomcat from '../assets/tomcat.png';
import jsp from '../assets/jsp.png';
import mysql from '../assets/mysql.png';
import uco from '../assets/uco.png';
import square from '../assets/square.png';

import newtype from '../assets/newtype.jpeg';
import beowulf from '../assets/beowulf.jpg';
import fortran from '../assets/fortran.jpg';

import octocat from '../assets/octocat.png';
export default {
    pi,
    bison,
    directions,
    dart,
    angular,
    email,
    mobile,
    api,
    cpp,
    css,
    figma,
    flutter,
    git,
    graphql,
    html,
    javascript,
    mu5,
    node,
    python,
    react,
    redux,
    sass,
    typescript,
    vue,
    about01,
    about02,
    about03,
    about04,
    profile,
    circle,
    logo,
    adidas,
    amazon,
    asus,
    bolt,
    nb,
    skype,
    spotify,
    java,
    profile2,
    anchor,
    kendo,
    homeScreen,
    firebase,
    scryfall,
    mtgio,
    ec2,
    tomcat,
    jsp,
    mysql,
    uco,
    square,
    newtype,
    beowulf,
    fortran,
    octocat
};