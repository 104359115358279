import React, { useState } from 'react';
import '../styles/Navbar.scss';
import { images } from '../constants';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { motion } from 'framer-motion';
import { BsChevronDown } from 'react-icons/bs';
import { GiShipWreck } from 'react-icons/gi'
const Navbar = () => {
    const [toggle, setToggle] = useState(false)

    return (
        <nav className="app__navbar">
            <div className="app__navbar-logo">
                <a href={'#home'}> <img src={images.logo} alt="logo" /></a>

            </div>
            <ul className="app__navbar-links">
                {['about', 'projects', 'publications', 'presentations', 'contact'].map((item) => (
                    <li className="app__flex p-text" key={`link-${item}`}>

                        <div className="anchor">⚓︎</div>
                        {/* <a href={`#${item}`}>{item}</a>*/}
                        {
                            /*(() => {
                                if (item == 'projects') {
                                    <div className='dropdown'>
                                        <a href={`#${item}`}>{item} <BsChevronDown /></a>
                                        <div className="dropdown-content">
                                            <a href="#crowned">Crowned Projects<div className='ship'><GiShipWreck /></div></a>
                                            <a href={`#${item}`}>Project Library<div className='ship'><GiShipWreck /></div></a>
                                        </div>
                                    </div>
                                }
                                else if (item == 'about') {

                                    <div className='dropdown'>
                                        <a href={`#${item}`}>{item} <BsChevronDown /></a>
                                        <div className="dropdown-content">
                                            <a href={`#${item}`}>Qualifications<div className='ship'><GiShipWreck /></div></a>
                                            <a href="#hats">My Hats<div className='ship'><GiShipWreck /></div></a>
                                        </div>
                                    </div>
                                }
                                else {
                                    (<a href={`#${item}`}>{item}</a>)
                                }
                            })*/

                            /*//uncomment this when crowned is completed
                            (item === 'projects')
                                ? (

                                    <div className='dropdown'>
                                        <a href={`#${item}`}>{item} <BsChevronDown /></a>
                                        <div className="dropdown-content">
                                            <a href="#crowned">Crowned Projects<div className='ship'><GiShipWreck /></div></a>
                                            <a href={`#${item}`}>Project Library<div className='ship'><GiShipWreck /></div></a>
                                        </div>
                                    </div>


                                )
                                : (item === 'about')
                                    ? (<div className='dropdown'>
                                        <a href={`#${item}`}>{item} <BsChevronDown /></a>
                                        <div className="dropdown-content">
                                            <a href={`#${item}`}>Qualifications<div className='ship'><GiShipWreck /></div></a>
                                            <a href="#hats">Experience<div className='ship'><GiShipWreck /></div></a>
                                        </div>
                                    </div>)
                                    : (<a href={`#${item}`}>{item}</a>)*/
                            (item === 'about')
                                ? (<div className='dropdown'>
                                    <a href={`#${item}`}>{item} <BsChevronDown /></a>
                                    <div className="dropdown-content">
                                        <a href={`#${item}`}>Qualifications<div className='ship'><GiShipWreck /></div></a>
                                        <a href="#hats">Experience<div className='ship'><GiShipWreck /></div></a>
                                    </div>
                                </div>)
                                : (<a href={`#${item}`}>{item}</a>)
                        }
                    </li>
                ))}
            </ul>
            <div className="app__navbar-menu">
                <HiMenuAlt4 onClick={() => setToggle(true)} />
                {
                    toggle && (
                        <motion.div
                            whileInView={{ x: [300, 0] }}
                            transition={{ duration: 0.85, ease: 'easeOut' }}>
                            <HiX onClick={() => setToggle(false)} />
                            <ul>
                                {['home', 'about', 'projects', 'publications', 'presentations', 'contact'].map((item) => (
                                    <li key={item}>
                                        <a href={`#${item}`} onClick={() => setToggle(false)}>{item}</a>
                                    </li>
                                ))}
                            </ul>
                        </motion.div>
                    )
                }
            </div>
        </nav >

    )
}

export default Navbar