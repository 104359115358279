import React, { useState, useEffect } from 'react';
import { IoBody } from 'react-icons/io5';
import Modal from '../components/Modal';




const WorkChild = ({ work }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <div className='bott-btn' onClick={() => {
                //document.body.style.overflow = 'hidden';
                // document.body.style.height = '100%';
                // document.querySelector("body").style.overflow = 'hidden';
                //document.getElementById("work").style.overflow = 'hidden';
                // document.getElementById("work").style.height = '100%';
                // disableBodyScroll(ref);
                setIsOpen(true);
            }}>More Details 🧭</div>
            <Modal work={work} open={isOpen} onClose={() => {
                //document.body.style.overflow = 'auto';
                //document.body.style.height = 'auto';
                // document.querySelector("body").style.overflow = 'auto';
                //document.getElementById("work").style.overflow = 'auto';
                // document.getElementById("work").style.overflow = 'auto';
                //enableBodyScroll(ref);
                setIsOpen(false);
            }} />
        </>
    )

}

export default WorkChild;