import React from 'react';
import './styles/App.scss';
import { About, Header, Skills, Contact, Work, Crowned, Hats, Publications } from './pages'
import { Navbar } from './components';


const App = () => {
  return (
    <div className="app">
      <Navbar />
      <Header />
      <About />
      <Hats />
      {/*<Crowned />*/}
      <Work />
      <Publications />
      <Skills />


      <Contact />


    </div>
  );
}

export default App;
